/*
 * @Author: Yoneyy (y.tianyuan)
 * @Date: 2024-01-09 10:12:22
 * @Last Modified by: Yoneyy (y.tianyuan)
 * @Last Modified time: 2024-08-05 14:35:06
 */

import { RouteObject } from "react-router-dom";
import {
  Home,
  lazyLoad,
  Forget,
  Login,
  Register,
  HomePage,
  Order,
  AfterSale,
  Finance,
  Account,
  Storage,
  Purchase,
  Selfprint,
  Draw,
  User,
  Accredit,
  Develop,
  Criticize,
  Data,
} from "../pages";
export type IRouter = RouteObject & {};

export const mainRoutes: IRouter[] = [
  {
    path: "/",
    element: lazyLoad(<Home />),
    children: [
      {
        path: "/home/mer/index",
        element: lazyLoad(<HomePage.MerLsit />), // 商家首页
      },
      {
        path: "/home/sup/index",
        element: lazyLoad(<HomePage.SupLsit />), // 渠道首页
      },
      {
        path: "/order/mer/list",
        element: lazyLoad(<Order.Orderlist />), // 商家订单列表
      },
      {
        path: "/order/mer/list",
        element: lazyLoad(<Order.MerLsit />), // 商家订单列表
      },
      {
        path: "/order/sup/list",
        element: lazyLoad(<Order.SupLsit />), // 渠道订单列表
      },
      {
        path: "/print/self",
        element: lazyLoad(<Selfprint.MerList />), // 商家自助打印
      },
      {
        path: "/print/sheet",
        element: lazyLoad(<Selfprint.SupList />), // 渠道自助打印
      },
      {
        path: "/selfprint/mer-setup",
        element: lazyLoad(<Selfprint.MerSetup />), // 商家打印设置
      },
      {
        path: "/draw/mer/list",
        element: lazyLoad(<Draw.MerList />), // 商家撤单管理
      },
      {
        path: "/draw/mer/detail/:revokeId",
        element: lazyLoad(<Draw.MerDrawDetail />), // 商家撤单详情
      },
      {
        path: "/draw/sup/list",
        element: lazyLoad(<Draw.SupList />), // 渠道撤单管理
      },
      {
        path: "/draw/sup/detail/:revokeId",
        element: lazyLoad(<Draw.SupDrawDetail />), // 商家撤单详情
      },
      {
        path: "/order/mer-details",
        element: lazyLoad(<Order.MerDetails />), //商家订单列表详情
      },
      {
        path: "/order/sup-details",
        element: lazyLoad(<Order.SupDetails />), //渠道订单列表详情
      },
      {
        path: "/aftersale/mer/list",
        element: lazyLoad(<AfterSale.MerList />), //商家售后管理
      },
      {
        path: "/after/sup/list",
        element: lazyLoad(<AfterSale.SupList />), //渠道售后管理
      },
      {
        path: "/aftersale/mer-detail",
        element: lazyLoad(<AfterSale.MerDetail />), //商家售后订单详情
      },
      {
        path: "/finance/mer/order/bill",
        element: lazyLoad(<Finance.MerPayments />), //商家货款账单new
      },
      {
        path: "/finance/mer/profit/list",
        element: lazyLoad(<Finance.MerProfitper />), //商家单品利润概览
      },
      // {
      //   path: "/finance/mer/order/bill",
      //   element: lazyLoad(<Finance.MerPayment />), //商家货款账单
      // },
      {
        path: "/finance/mer/after/bill",
        element: lazyLoad(<Finance.MerAftersalebill />), //商家售后账单
      },
      {
        path: "/finance/sup/order/bill",
        element: lazyLoad(<Finance.WhsPayments />), //渠道货款账单new
      },
      // {
      //   path: "/finance/sup/order/bill",
      //   element: lazyLoad(<Finance.SupPayment />), //渠道货款账单
      // },
      {
        path: "/finance/mer/sup/bill/finsh/:settl_id/:settl_amount",
        element: lazyLoad(<Finance.Finshsettlement />), //完成详情页面
      },
      {
        path: "/finance/mer/statelysis/list",
        element: lazyLoad(<Finance.MerStatelysis />), //商家财务报表
      },
      {
        path: "/finance/sup/after/bill",
        element: lazyLoad(<Finance.SupAftersalebill />), //渠道售后账单
      },
      {
        path: "/storage/mer/list",
        element: lazyLoad(<Storage.MerList />), //商家商品管理
      },
      {
        path: "/storage/sup/list",
        element: lazyLoad(<Storage.SupList />), //渠道商品管理
      },
      {
        path: "/storage/mer/pur/list",
        element: lazyLoad(<Purchase.MerPurchase />), //商家采购管理
      },
      {
        path: "/storage/addpurlist",
        element: lazyLoad(<Purchase.Addpurlist />), //商家添加采购单
      },
      {
        path: "/storage/sup/pur/list",
        element: lazyLoad(<Purchase.SupPurchase />), //渠道采购管理
      },
      {
        path: "/storage/auto/patch",
        element: lazyLoad(<Storage.MerAutomatic />), //商家自动分配
      },
      {
        path: "/storage/sup-addlist",
        element: lazyLoad(<Storage.SupAddList />), //渠道添加商品
      },
      {
        path: "/account/shop/list",
        element: lazyLoad(<Account.MerMyshop />), //商家我的店铺
      },
      {
        path: "/account/supplier/list",
        element: lazyLoad(<Account.SupSupplier />), // 商家我的渠道
      },
      {
        path: "/account/merchant/list",
        element: lazyLoad(<Account.MerSupplier />), // 渠道我的商家
      },
      {
        path: "/account/person/:tabdefaultval?",
        element: lazyLoad(<Account.ThePerson />), //员工管理
      },
      {
        path: "/account/addstaff/:usertype/:userId?",
        element: lazyLoad(<Account.AddStaff />), //添加员工
      },
      {
        path: "/account/rolesaveform/:roletype/:roleid?",
        element: lazyLoad(<Account.Rolesaveform />), //员工管理
      },
      {
        path: "/account/main",
        element: lazyLoad(<User.TheMain />), //商家用户中心
      },
      {
        path: "/account/black/list",
        element: lazyLoad(<Account.MerBlack />), //商家黑户管理
      },
      {
        path: "/account/black/wire",
        element: lazyLoad(<Account.SupWholeBlack />), //渠道全网黑户
      },
      {
        path: "/accredit/defeated",
        element: lazyLoad(<Accredit.Defeated />), //授权失败
      },
      {
        path: "/accredit/succeed",
        element: lazyLoad(<Accredit.Succeed />), //授权成功
      },
      {
        path: "/wait/develop/",
        element: lazyLoad(<Develop.Waiting />), //开发中
      },
      {
        path: "/criticize/mer/comment/list",//评价管理
        element: lazyLoad(<Criticize.MerCommentList />)
      },


      // 数据中心
      {
        path: "data",
        children: [
          {
            path: "complaint",
            index: true,
            element: lazyLoad(<Data.Complaint />),
          },
          {
            path: "after",
            element: lazyLoad(<Data.After />),
          }
        ]
      }
    ],
  },
  {
    path: "/sign",
    children: [
      {
        path: "login",
        element: lazyLoad(<Login />),
      },
      {
        path: "register",
        element: lazyLoad(<Register />),
      },
      {
        path: "forget",
        element: lazyLoad(<Forget />),
      },
    ],
  },
];
