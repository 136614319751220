import { lazy } from 'react';
// 商家我的店铺
export const MerMyshop = lazy(() => import('./mer-myshop'));

// 商家我的渠道
export const MerSupplier = lazy(() => import('./mer-supplier'));
// 渠道我的商家
export const SupSupplier = lazy(() => import('./sup-supplier'));

// 员工管理
export const ThePerson = lazy(() => import('./the-person'));
//保存员工
export const AddStaff = lazy(() => import('./addstaff'));
//保存角色
export const Rolesaveform = lazy(() => import('./rolesaveform'));
//黑户管理
export const MerBlack = lazy(() => import('./mer-black'));
//全网管理
export const SupWholeBlack = lazy(() => import('./sup-wholeblack'));