import { lazy } from 'react';
// 商家订单管理
export const MerLsit = lazy(() => import('./mer-list'));
// 商家订单详情
export const MerDetails = lazy(() => import('./mer-details'));
// 商家订单详情
export const SupDetails = lazy(() => import('./sup-details'));
// 渠道订单列表
export const SupLsit = lazy(() => import('./sup-list'));

// 订单列表
export const Orderlist = lazy(() => import('./order'));

