import { lazy } from 'react';

// 商家采购管理
export const MerPurchase = lazy(() => import('./mer-purchase'));

// 渠道采购管理
export const Addpurlist = lazy(() => import('./addpurlist'));

// 渠道采购管理
export const SupPurchase = lazy(() => import('./sup-purchase'));


