/*
 * @Author: Yoneyy (y.tianyuan)
 * @Date: 2024-01-09 10:04:03
 * @Last Modified by: Yoneyy (y.tianyuan)
 * @Last Modified time: 2024-01-09 10:44:36
 */

import { FC, Suspense } from "react";
import axios from "axios";
import { BASE_URL } from "./configs";
import { mainRoutes } from "./routes";
import { useRoutes } from "react-router-dom";
import { MD5 } from "crypto-js";
import { APP_KEY } from "./configs";
import "./assets/styles/rest.less";
import "./index.less";
import { message } from "antd";

axios.defaults.baseURL = BASE_URL;

const App: FC<{}> = () => {
  //请求拦截器
  axios.interceptors.request.use(
    (config) => {
      const ts = Math.floor(Date.now() / 1000);
      const { method, params, data: body } = config;
      const query = {
        ts,
        app_key: APP_KEY,
        ...params,
      };
      for(var paramskey in query) {
        if(!query[paramskey]) {
          delete query[paramskey]
        }
      }
      const qs: string[] = [];
      const keys = Reflect.ownKeys(query).sort();

      for (const k of keys) qs.push(`${k as string}=${query[k]}`);
      if (method?.toLocaleUpperCase() === "POST") {
        const data = JSON.stringify(body);
        qs.push(`_param=${data.replace(/\s+/gi, "")}`);
      }
      const sign = MD5(qs.join("&"));
      config.params = query;
      config.headers["X-Req-Sign"] = sign;
      const authStorage = localStorage.getItem('authorization');
      if(authStorage) {
        config.headers["authorization"] = "Bearer "+authStorage;
      }
      config.headers["Content-Type"] = method==='put'?"multipart/form-data":config.headers["Content-Type"];
      // config.headers['X-Forwarded-For'] = '1.203.98.10'
      return config;
    },
    (error) => {
      // 对请求错误做些什么
      console.error(error);
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      // 对响应数据做点什么
      if(response.data.code === 401) {
        message.error(response.data.msg);
        window.top?.location.assign('/sign/login');
      }
      return response;
    },
    function (error) {
      // 对响应错误做点什么
      console.error('Error:', error);
      return Promise.reject(error);
    }
  );
  return <Suspense fallback={""}>{useRoutes(mainRoutes)}</Suspense>;
};

export default App;