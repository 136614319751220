import { lazy } from 'react';
// 商家货款账单
export const MerPayment = lazy(() => import('./mer-payment'));
// 商家售后账单
export const MerAftersalebill = lazy(() => import('./mer-aftersalebill'));
// 渠道货款账单
export const SupPayment = lazy(() => import('./sup-payment'));
// 渠道售后账单
export const SupAftersalebill = lazy(() => import('./sup-aftersalebill'));

// 商家货款账单 new
export const MerPayments = lazy(() => import('./m-payments'));

// 仓库货款账单 new
export const WhsPayments = lazy(() => import('./w-payments'));

// 货款账单-仓库端-完成详情
export const Finshsettlement = lazy(() => import('./finshsettlement'));

// 商家单品利润
export const MerProfitper = lazy(() => import('./profitper/m-profitper'));

// 商家端-财务报表
export const MerStatelysis = lazy(() => import('./financialstate/m-statelysis'));