/*
 * @Author: Yoneyy (y.tianyuan)
 * @Date: 2024-01-09 10:12:45
 * @Last Modified by: Yoneyy (y.tianyuan)
 * @Last Modified time: 2024-08-05 14:33:31
 */

import { ReactNode, Suspense, lazy } from 'react';

/**
 * lazyLoad
 * @param children
 * @returns
 */
export const lazyLoad = (children: ReactNode): ReactNode => <Suspense fallback={''}>{children}</Suspense>;

//订单管理
export * as Order from './Order';

//仓储管理
export * as Storage from './Storage';

//采购管理
export * as Purchase from './Purchase';

//售后管理
export * as AfterSale from './AfterSale';

//财务管理
export * as Finance from './Finance';

//账户管理
export * as Account from './Account';

//打印订单
export * as Selfprint from './Selfprint';

//撤单管理
export * as Draw from './Draw';

//用户中心
export * as User from './User';

//用户中心
export * as Accredit from './Accredit';

// 首页
export * as HomePage from './HomePage';

// 开发中
export * as Develop from './Develop';

// Home
export const Home = lazy(() => import('./Home'));

// Login
export const Login = lazy(() => import('./Sign/login'));

// Register
export const Register = lazy(() => import('./Sign/register'));

// Forget
export const Forget = lazy(() => import('./Sign/forget'));

//评价管理
export * as Criticize from './Criticize';

// Data center
export * as Data from './Data';