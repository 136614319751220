// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/.pnpm/css-loader@7.1.2_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/.pnpm/css-loader@7.1.2_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ul,
ol {
  list-style: none;
}
@media (max-width: 768px) {
  .ant-table {
    width: 100%;
    overflow-x: auto;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > th,
  .ant-table-thead > tr > td,
  .ant-table-tbody > tr > td {
    white-space: pre;
  }
  .ant-table-thead > tr > th > span,
  .ant-table-tbody > tr > th > span,
  .ant-table-thead > tr > td > span,
  .ant-table-tbody > tr > td > span {
    display: block;
  }
}
`, "",{"version":3,"sources":["webpack://./src/index.less"],"names":[],"mappings":"AAAA;;;EAGE,YAAA;EACA,WAAA;EACA,SAAA;EACA,UAAA;EACA,sBAAA;AACF;AAEA;EACE,kCAAA;EACA,mCAAA;EACA,kCAAA;AAAF;AAEA;;EAEE,gBAAA;AAAF;AAGA;EACE;IACE,WAAA;IACA,gBAAA;EADF;EAEE;;;;IAII,gBAAA;EAAN;EAJE;;;;IAMM,cAAA;EAIR;AACF","sourcesContent":["html,\nbody,\n#root {\n  height: 100%;\n  width: 100%;\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\nbody {\n  text-rendering: optimizeLegibility;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\nul,\nol {\n  list-style: none;\n}\n\n@media (max-width: 768px) {\n  .ant-table {\n    width: 100%;\n    overflow-x: auto;\n    &-thead > tr,\n    &-tbody > tr {\n      > th,\n      > td {\n        white-space: pre;\n        > span {\n          display: block;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
