import { lazy } from 'react';
// 商家撤单列表
export const MerList = lazy(() => import('./mer-list'));

// 渠道撤单列表
export const SupList = lazy(() => import('./sup-list'));

// 撤单详情
export const MerDrawDetail = lazy(() => import('./mer-detail'));
export const SupDrawDetail = lazy(() => import('./sup-detail'));


