/*
 * @Author: Yoneyy (y.tianyuan)
 * @Date: 2024-08-05 14:26:55
 * @Last Modified by: Yoneyy (y.tianyuan)
 * @Last Modified time: 2024-08-05 14:32:26
 */

import { lazy } from "react";

// 客诉数据
export const Complaint = lazy(() => import("./complaint"));

// 售后数据
export const After = lazy(() => import("./after"));